body{
  font-family: 'CorisandeLight';
  background: linear-gradient(269.64deg, #83277F -5.89%, #00B5E8 137.38%);
  color:#FFF;
}
.Main_ar{
  font-family: 'Bitter';
  text-align: right;
}

@font-face {
  font-family: 'CorisandeLight';
  src: url('./fonts/corisanderegular.ttf') ;

  }

@font-face {
  font-family: 'Bitter';
  src: url('./fonts/DINNextLTArabic-Regular-3.ttf');
  /* Add other font properties if needed */
}

.section-padding {
  padding: 10px 150px;
  }
  .section-spacing{
    margin-top: 40px;
  }
@media screen and (min-width: 1025px) and (max-width: 1199.99px) {
  .section-padding {
      padding: 20px 28px;
  }
  
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .section-padding {
      padding: 20px 28px;
  }
  
  
}

@media screen and (min-width: 601px) and (max-width: 767.999px) {
  .section-padding {
      padding: 20px 28px;
  }
 
}
@media screen and (max-width: 600px) {
  .section-padding {
      padding: 20px 28px;
  }

}

@media screen and (max-width: 320px) {
  .section-padding {
      padding: 20px 28px;
  }
}